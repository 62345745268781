<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="자산관리 등록">
            - 회사에서 보유중인 자산의 경우 “보유” 상태, 사용자에게 지급된 자산은 “지급” 상태로 등록해 주세요.<br>
            - 사용자에게 지급된 자산의 경우, 해당 사용자를 반드시 지정해 주세요.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td>
                                    <select name="cat1" id="cat1" class="w-200px mr-10" v-model="add.cat1" @change="add.setCat1">
                                        <option value="">중분류 선택</option>
                                        <option :value="irow" v-for="(irow, i) in add.categories" :key="i">{{ irow.name }}</option>
                                    </select>
                                    <select name="cat2" id="cat2" class="w-200px" v-model="add.cat2">
                                        <option value="">소분류 선택</option>
                                        <option :value="v" v-for="(v, k) in add.cat1.children" :key="k">{{ v }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 모델명</th>
                                <td>
                                    <input type="text" class="w-100per" maxlength="50" v-model.trim="add.model">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시리얼</th>
                                <td>
                                    <input type="text" class="w-100per" maxlength="50" v-model.trim="add.serialno">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 취득단가</th>
                                <td>
                                    <input type="number" class="w-200px number" maxlength="50" v-model.number="add.price">
                                    <span class="ml-10">원</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 구입일</th>
                                <td>
                                    <v-datepicker v-model="add.buy_date" class="datepicker-group">
                                        <template #default="{ inputValue, togglePopover }">
                                            <div>
                                                <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                <button class="btn-cal" @click="togglePopover()"></button>
                                            </div>
                                        </template>
                                    </v-datepicker>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 용도</th>
                                <td>
                                    <label><input type="radio" id="usage1" name="usage" value="개인용" v-model="add.usage"><span class="ml-5 mr-20"> 개인용</span></label>
                                    <label><input type="radio" id="usage2" name="usage" value="부서용" v-model="add.usage"><span class="ml-5 mr-20"> 부서용</span></label>
                                    <label><input type="radio" id="usage3" name="usage" value="대여용" v-model="add.usage"><span class="ml-5 mr-20"> 대여용</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 상태</th>
                                <td>
                                    <label><input type="radio" id="state1" name="state" value="지급" v-model="add.state"><span class="ml-5 mr-20"> 지급</span></label>
                                    <label><input type="radio" id="state2" name="state" value="보유" v-model="add.state"><span class="ml-5 mr-20"> 보유</span></label>
                                    <label><input type="radio" id="state3" name="state" value="폐기요청" v-model="add.state"><span class="ml-5 mr-20"> 폐기요청</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>사용자</th>
                                <td>
                                    <!-- <select name="hq_office" id="hq_office" class="w-200px" v-model="add.idx_office">
                                        <option value="">본부 선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in add.hq_office" :key="i">{{ irow.kname }}</option>
                                    </select>
                                    <select name="hq_team" id="hq_team" class="w-200px ml-10" v-model="add.idx_team">
                                        <option value="">팀 선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in add.hq_team" :key="i">{{ irow.kname }}</option>
                                    </select>
                                    <select name="idx_hq" id="idx_hq" class="w-200px ml-10" v-model="add.idx_hq">
                                        <option value="">HQ매니저 선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in add.hq_list" :key="i">{{ irow.kname }}</option>
                                    </select> -->
                                    <carrot-sector v-model="add.idx_sector" class="w-200px"></carrot-sector>
                                    <carrot-dept :idx_sector="add.idx_sector" v-model="add.idx_office" class="w-200px"></carrot-dept>
                                    <carrot-team :idx_sector="add.idx_sector" :idx_office="add.idx_office" v-model="add.idx_team" class="w-200px ml-10"></carrot-team>
                                    <carrot-staff :idx_sector="add.idx_sector" :idx_office="add.idx_office" :idx_team="add.idx_team" v-model="add.idx_hq" class="w-200px ml-10"></carrot-staff>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(변경사항)</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="add.etc_memo" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
                    <button class="btn-default float-right mt-30" @click="add.doSubmit">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import { DatePicker } from 'v-calendar'
import CarrotSector from '@/components/common/CarrotSector.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",
    components: {
        'v-datepicker' : DatePicker,
        CarrotDept,
        CarrotTeam,
        CarrotStaff,
        CarrotSector
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const add = reactive({
            categories : [],
            hq_office  : [],
            hq_team    : [],
            hq_list    : [],

            sdate : new Date(), edate : new Date(),
            cat1 : '', cat2 : '',
            model    : '',
            serialno : '',
            price    : '',
            buy_date : '',
            usage    : '개인용',
            state    : '지급',
            idx_sector:'0',
            idx_office : '0',
            idx_team : '0',
            idx_hq   : '0',
            etc_memo : '',

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        add.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchOffice : () => {
                let params = {};
                axios.get("/rest/mypims/getHQOfficeList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        add.hq_office = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchTeam : () => {
                let params = {
                    idx_office : add.idx_office
                };
                axios.get("/rest/mypims/getHQTeamList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        add.hq_team = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearchHQ : () => {
                let params = {
                    idx_office : add.idx_office,
                    idx_team   : add.idx_team
                };
                axios.get("/rest/mypims/getHQList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        add.hq_list = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    cat2  : add.cat2,

                    model    : add.model,
                    serialno : add.serialno,
                    price    : add.price,
                    buy_date : add.buy_date,
                    usage    : add.usage,
                    state    : add.state,
                    idx_sector : add.idx_sector,
                    idx_office : add.idx_office,
                    idx_team : add.idx_team,
                    idx_hq   : add.idx_hq,
                    etc_memo : add.etc_memo
                };

                if( typeof add.cat1 == 'undefined' || add.cat1 == '' || add.cat1.name == '' ){
                    // alert("중분류를 선택하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                } else {
                    params.cat1 = add.cat1.name;
                }

                if( params.cat2 == "" ){
                    // alert("소분류를 선택하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                if( params.model == "" ){
                    // alert("모델명을 입력하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                if( params.serialno == "" ){
                    // alert("시리얼을 입력하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                if( params.price == "" || params.price == "0" ){
                    // alert("취득단가를 입력하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                if( params.buy_date == "" ){
                    // alert("구입일을 입력하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }
                if( params.usage == "" ){
                    // alert("용도를 선택하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }
                if( params.state == "" ){
                    // alert("상태를 선택하세요.");
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                // 한/영, 숫자, 특수문자 500자 이내
                if( params.etc_memo.length >= 500 ){
                    toast.error("500자 이하로 입력하세요.");
                    return;
                }

                axios.post("/rest/mypims/addAssets", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("자산을 등록하였습니다.");
                        router.push({
                            name : 'myPIMS-AssetManagementList'
                        });
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            goList : () => {
                if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.go(-1);
                }
            }
        });

        onMounted(() => {
            // Mounted
            // 총무팀은 제거.
            let auth = false;
            if( [ '124'].indexOf(String(store.state.idx_staff)) > -1 || store.state.idx_team == 6116){// 수정권한은 회계팀 macy, louis,Ellie K, Ella Y, Ahri
              auth = true;
            }

            if(auth==false) {
                router.go(-1);
                Swal.fire({
                    title : '자산관리 등록',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            add.getCategory();
            // add.doSearchOffice();
        });

        onUnmounted(() => {

        });

        return { add };
    }
}
</script>

<style lang="scss" scoped>
</style>